html {
  font-size: 62.5%;
  font-family: $font; 
}
.p,p,li {
  font-size: 1.6rem;
  font-family: $font;
  line-height: 1.6em;
  margin-top: 0;
  color:$grey;
  &:last-child {margin-bottom: 0}
}
ul,ol {
  margin: 1.5em 0 2em;
  &:first-child {margin-top: 0}
  &:last-child {margin-bottom: 0}
}
h1,h2,h3,h4,h5,h6 {
  margin: 1em 0 .4em;
  line-height: 1.4em;
  color: $blue;
  font-family: $font;
  &:first-child {margin-top: 0}
  &:last-child {margin-bottom: 0}
}
.white-text {
  h1,h2,h3,h4,h5,h6,p,li {color: #fff}
}
li {
  font-size: 1.6rem;
  line-height: 1.4em;
  margin: .7em 0;
}
a {
  color: $blue;
  text-decoration: none;
}
blockquote {
  max-width: 600px;
  margin: 6rem auto;
  line-height: 1.6em;
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  color: $blue;
  &:first-child {margin-top: 0}
  span {
    display: block;
    color: $grey;
    font-size: 1.6rem;
    font-weight: normal;
    margin-top: 1rem;
  }
}
h1,.h1 {
  font-size: 3rem;
  font-weight: bold;
  font-family: $font;
  &.larger {
    font-size: 3.4rem;
    font-weight: 700;
    margin-bottom: 1em;
  }
}
h2,.h2 {
  font-size: 2.6rem;
  font-weight: bold;
}
h3,.h3 {
  font-size: 2.4rem;
  font-weight: normal;
}
h4,.h4 {
  font-size: 2.2rem;
  font-weight: normal;
}
h5,.h5 {
  font-size: 1.8rem;
  font-weight: normal;
}
h6,.h6 {
  font-size: 1.6rem;
  font-weight: bold;
  font-family: $font;
  line-height: 1em;
}
@media only screen and (max-width: 950px) {
  h1.larger {font-size: 3.7rem}
  p {font-size: 1.6rem}
}
@media only screen and (max-width: 785px) {
  html {font-size: 55%}
}
@media only screen and (max-width: 685px) {
  h1 {
    font-size: 2.8rem;
    &.larger {font-size: 3.2rem}
  }
}
