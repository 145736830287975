
header.main {
  background-color: $blue;
  color: #fff;
  position: sticky;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  top: 0;
  left: 0;
  @include shadow;
  .container {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
  }
}
#logo {
  width: 18rem;
  display: block;
}
nav #mobile-menu {display: none}
nav > ul {
  @include zeroList;  
  @include flexbox;
  > li {
    position: relative;
    > a, > span {
      display: block;
      padding: 2rem 2rem;
      font-size: 1.5rem;
      text-decoration: none;
      color: #fff;
      @include transition(color,.3s);
    }
    &.twitter {
      > a {
        padding: 1.3rem 0 .8rem 2rem;
        img {
          width: 35px;
          filter: brightness(100);
          &:hover {filter: brightness(1)}
        }
      }
    }
    &:hover {
      > a, > span {color: $lightBlue}
    }
    &.active > a,&.active > span {color: $lightBlue}
    &.download-app > a {
      padding: .5rem 2rem;
      border: 2px solid $lightBlue;
      margin-top: 1.3rem;
      border-radius: .5rem;
      margin-left: 2rem;
    }
    &:hover ul {display: block}
    &.has-children {
      > span:after {
        content: ' ';
        display: inline-block;
        width: 9px;
        height: 5px;
        background: transparent url(../images/down-carat.png) no-repeat center center;
        margin-left: 10px;
        position: relative;
        top: -2px;
      }
    }
    ul {
      @include zeroList;
      position: absolute;
      min-width: 100%;
      top: 100%;
      left: 0;
      background-color:$blue;
      padding: 0 0 1rem;
      display: none;
      li {
        display: block;
        a {
          padding: .5rem 2rem;
          display: block;
          color: #fff;
          &:hover {
            color: $lightBlue
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  nav ul {
    a {
      font-size: 1.4rem;
      padding: 1.5rem 1.5rem;
    }
    li:last-child a {
      margin-top: 1rem;
      padding: .3rem 1.5rem;
    }
  }
}
@media only screen and (max-width: 885px) {
  header.main .container {
    padding-top: 1.5rem;
    @include flex-direction(column);
  }
  #logo {width: 25rem}
  nav ul li:last-child a {margin-right: 0}
}

@media only screen and (max-width: 600px) {
  header.main .container {
    @include flexbox;
    @include justify-content(space-between);  
    @include flex-direction(row);
    padding: 1.5rem 0;
  }
  nav {
    #mobile-menu {
      display: block;
      position: relative;
      width: 25px;
      height: 18px;
      cursor: pointer;
      &:after,&:before,span {
        content: ' ';
        display: block;
        width: 25px;
        height: 2px;
        transition: background-color .3s;
        background-color: #fff;
        position: absolute;
      }
      &:after {bottom: 0;}
      span {top: 8px;}
      &:hover {
        &:before,&:after,span {background-color: $lightBlue;}
      }
    }
    > ul {
      top: 100%;
      right: 0;
      position: absolute;
      @include flex-direction(column);
      background-color: $blue;
      padding: 1rem 0;
      display: none;
      &.open {display: flex;}
      > li {
        > a, > span {
          padding: 1rem 2rem;
          font-size: 1.8rem;
        }
        &.download-app {
          margin-right: 1.5rem;
        }
        ul {
          display: block;
          position: relative;
          margin: 0;
          padding: 0;
          a {
            margin-top: 0 !important;
            padding: 1rem 2rem !important;
            font-size: 1.8rem;
            &:before {
              content: ' ';
              display: inline-block;
              width: 10px;
              height: 1px;
              background-color: #fff;
              position: relative;
              top: -.5rem;
              margin-right: .5rem;
            }
          }
        }
      }
    }
  }
}