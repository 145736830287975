#docs {
  @include align-items(stretch);
  min-height: 90vh;
  .content-col {
    @include flex(1 1 68.5rem);
    overflow: auto;
  }
  // adds space before anchor links (for fixed header)
  :target:before {
    content:"";
    display:block;
    height:12rem; /* fixed header height*/
    margin-top:-12rem; /* negative fixed header height */
  }
  // add line above h2 - breaks target links
  h2 {
    border-bottom: 1px rgba($blue, 0.2) solid;
    padding-bottom: 0.5rem;
  }
  // code
  .highlighter-rouge {
    color: #e23232;
  }
  // tables
  table {
    font-size: 1.6rem;
    padding: 1rem;
    th {
      border-bottom: 1px solid $lightGrey;
    }
    th, td {
      text-align: left;
      padding: 0.5rem 1rem;
    }
  }
  img {
    display: block;
  }
  figure {
    margin: 1em 0 1em 0;
  }
  pre {
    font-size: 1.2rem;
  }
  .store-badge {
    display: inline;
  }
  // mobile / screenshots
  .mobile, .screenshot {
    border-radius: 0.5rem;
    @include shadowlift;
  }
  .mobile {
    display: inline;
    width: 250px;
    height: auto;
    margin-left: 1em;
  }
  .hamburger-icon {
    display: inline;
    width: 1em;
    height: auto;
  }
  blockquote {
    text-align: left;
    max-width: unset;
    font-weight: unset;
    border-radius: 0.2em;
    background-color: lighten($grey, 55%);
    padding: 0.5em 0.5em;
    margin: 1em 0.2em;

    // // left line
    // margin: 1em 0;
    // padding-left: 10px;
    // margin-left: 0px;
    // border-left: 1px lighten($grey, 40%) solid;
    // p {
    //   color: lighten($grey, 20%);
    // }
  }
}
#sidebar-toc,#sidebar {
  position: sticky;
  top: 12rem;
}
.sidebar-nav-container {
  @include flex(0 0 20rem);
}
#sidebar {
  @include zeroList;
  border-right: 1px solid $lightGrey;
  span {
    font: normal 600 1.4rem/1em $font;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    text-align: left;
    padding: 1rem 2.5rem;
    margin-top: 3rem;
    display: block;
    color: $blue;
  }
  li {
    &:first-child span {margin-top: 0}
    ul  {
      @include zeroList;
      a {
        line-height: 1.3;
        font-size: 1.4rem;
        display: block;
        text-align: left;
        padding: 0 2.5rem 0.8rem 2.5rem;
        &:hover {
          color: $lightBlue;
        }
      }
    }
    &.active a {color: $lightBlue}
  }
}
.sidebar-toc-container {
  @include flex(0 0 20rem);

  .report-issue {
    color: lighten($grey, 20%);
  }
  .tag-H2 {  }
  .tag-H3 {
    padding-left: 3rem !important;
  }
}
#sidebar-toc {
  @include zeroList;
  border-left: 1px solid $lightGrey;
  > li {
    > a {
      display: block;
      font-size: 1.2rem;
      line-height: 1.2em;
      padding: 0 2rem 0.8rem 2rem;
      &:hover {color: $lightBlue}
    }
  }
}
