.container {
  max-width: 96rem;
  margin: 0 auto;
  &.small {
    max-width: 86rem;
  }
  &.smaller {
    max-width: 65rem;
  }
}
img {max-width: 100%;}
.lift {
  @include transitionMult(box-shadow,.25s,transform,0.25s);
}
.lift:hover, .lift:focus {
  @include shadowlift;
  transform: translate3d(0,-3px,0);
}
.rounded-button,input[type=submit] {
  display: inline-block;
  border-radius: .5rem;
  padding: 1.5rem 1.5rem 1.4rem;
  border: .2rem solid $lightBlue;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 800;
  letter-spacing: .15rem;
  text-transform: uppercase;
  line-height: 1em;
  color: #fff;
  background-color: transparent;
  @include transitionMult(color,.25s,transform,0.25s);
  font-family: $font;
  cursor: pointer;
  &:hover, &.blue:hover {
    color: $lightBlue;
    transform: translate3d(0,-3px,0);
    @include shadowlift;
  }
  &.white-bg {
    color: $blue;
  }
  &.blue {
    background-color: $blue;
    border: 0;
    @include shadow;
  }
}
h2+.rounded-button {
  margin-top: 1.5rem;
}
.page-well {
  padding-left: 5rem;
  padding-right: 5rem;
}
.padding-top-bottom {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.text-center {text-align: center;}
.text-right {text-align: right;}
.flex {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
}
.grey-bg {
  background-color: $lightGrey;
}
section.video {
  margin: 0 auto;  
  max-width: 160rem;
	position: relative;
}
.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  display: block;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.icon {
  max-width: 10rem;
}

@media only screen and (max-width: 950px) {
  .padding-top-bottom {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}
@media only screen and (max-width: 785px) {
  .page-well {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media only screen and (max-width: 685px) {  
  .padding-top-bottom {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .flex {
    @include flex-direction(column);
    @include align-items(flex-start);
    > * {
      margin-bottom: 1.5rem;
      &:last-child {margin-bottom: 0}
    }
  }
}
